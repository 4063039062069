<template>
  <div>
    <v-btn @click="handleOpen">
      Filters {{ filters.length > 0 ? '/ ' + filters.length : null }}
      <v-icon right> {{ icons.mdiChevronDown }} </v-icon>
    </v-btn>
    <div class="card-wrapper">
      <v-card class="card-filter" v-if="open">
        <v-card-text>
          <div class="cont-filters">
            <div class="filter" v-for="(filter, index) in filters" :key="index">
              <div class="filter-row">
                <v-select
                  class="operator"
                  v-model="filter.operator"
                  :items="index != 0 ? operators : [operators[0]]"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="handleFilter"
                />
                <v-select
                  :items="columns"
                  v-model="filter.column"
                  class="column"
                  label="Column"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="handleFilter"
                />
                <v-select
                  :items="conditions"
                  v-model="filter.condition"
                  class="condition"
                  label="Condition"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  hide-details
                  @change="handleFilter"
                />
                <v-text-field
                  v-if="filter.condition != 'range'"
                  v-model="filter.value"
                  label="Value"
                  :disabled="filter.condition == 'is_empty'"
                  outlined
                  dense
                  hide-details
                  @keyup="handleFilter"
                />
                <v-text-field
                  v-if="filter.condition == 'range'"
                  v-model="filter.start"
                  label="From"
                  outlined
                  dense
                  hide-details
                  @keyup="handleFilter"
                />
                <v-text-field
                  v-if="filter.condition == 'range'"
                  v-model="filter.end"
                  label="To"
                  outlined
                  dense
                  hide-details
                  @keyup="handleFilter"
                />
              </div>
              <v-btn icon @click="removeFilter(index)">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="addFilter">
            <v-icon right> {{ icons.mdiPlus }} </v-icon>
            Add new filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { defineProps } from 'vue'
import { ref, computed, watch, onBeforeMount } from '@vue/composition-api'
import { mdiChevronDown, mdiPlus, mdiClose } from '@mdi/js'

export default {
  props: {
    headers: Array,
    data: Array,
    data_filter: Array,
    setLoading: Function,
    updateData: Function,
  },
  emits: ['update:data_filter'],
  setup(props, { emit }) {
    const open = ref(false)
    const filters = ref([])

    const operators = [
      { text: 'And', value: 'and' },
      { text: 'Or', value: 'or' },
    ]
    const conditions = [
      { text: 'Is', value: 'is' },
      { text: 'Is not', value: 'is_not' },
      { text: 'Is empty', value: 'is_empty' },
      { text: 'Range', value: 'range' },
      { text: 'Contains', value: 'contains' },
      { text: "Doesn't contains", value: 'doesnt_contains' },
      { text: 'Start with', value: 'start_with' },
      { text: 'End with', value: 'end_with' },
    ]
    const columns = props.headers ?? []

    const handleOpen = () => {
      open.value = !open.value
    }

    const removeFilter = index => {
      filters.value.splice(index, 1)
      if (filters.value[0]) {
        filters.value[0].operator = 'and'
      }
      handleFilter()
    }

    const clickOutside = () => {
      open.value = false
    }

    const addFilter = () => {
      let index = filters.value.length
      filters.value.push({
        operator: 'and',
        column: '',
        condition: '',
        value: '',
        remove: true,
      })
    }

    const handleCondition = (record, { column, condition, value, start, end }) => {
      if (condition == 'is') {
        if (record[column] == value) {
          return true
        }
      } else if (condition == 'is_not') {
        if (record[column] != value) {
          return true
        }
      } else if (condition == 'is_empty') {
        if (record[column] === '' || record[column] === null || record[column] === undefined) {
          return true
        }
      } else if (condition == 'range') {
        if (start && end) {
          if (record[column] >= start && record[column] <= end) {
            return true
          }
        } else if (start && !end) {
          if (record[column] >= start) {
            return true
          }
        } else if (!start && end) {
          if (record[column] <= end) {
            return true
          }
        }
      } else if (condition == 'contains') {
        if (String(record[column]).includes(value)) {
          return true
        }
      } else if (condition == 'doesnt_contains') {
        if (!String(record[column]).includes(value)) {
          return true
        }
      } else if (condition == 'start_with') {
        if (String(record[column]).startsWith(value)) {
          return true
        }
      } else if (condition == 'start_with') {
        if (String(record[column]).endsWith(value)) {
          return true
        }
      }

      return false
    }

    const evalFilter = record => {
      let conditions = ''
      filters.value.forEach(filter => {
        if (conditions == '') {
          if (filter.column && filter.condition) {
            conditions += handleCondition(record, filter)
          }
        } else {
          if (filter.column && filter.condition) {
            if (filter.operator == 'and') {
              conditions += ` && `
            } else if (filter.operator == 'or') {
              conditions += ` || `
            }

            conditions += handleCondition(record, filter)
          }
        }
      })

      if (conditions == '') {
        return true
      } else {
        return eval(conditions)
      }
    }

    const handleFilter = () => {
      if (props.setLoading) {
        props.setLoading(true)
      }
      const data_filter = props.data.filter(record => {
        return evalFilter(record)
      })
      if (props.setLoading) {
        props.setLoading(false)
      }
      if (props.updateData) {
        props.updateData(data_filter)
      }
    }

    onBeforeMount(() => {
      handleFilter()
    })

    return {
      filters,
      open,

      operators,
      conditions,
      columns,

      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClose,
      },

      handleFilter,
      handleOpen,
      addFilter,
      removeFilter,
      clickOutside,
    }
  },
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
}
.card-filter {
  margin-top: 10px;
  position: absolute;
  z-index: 10000;
  width: 100%;
  max-width: 742px;
}
.cont-filters {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.filter-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.operator {
  max-width: 100px;
  min-width: 100px;
}
.column {
  max-width: 150px;
  min-width: 150px;
}
.condition {
  max-width: 150px;
  min-width: 150px;
}
</style>
