<template>
  <div>
    <div class="header-top" v-if="shared != true">
      <v-btn color="primary" :disabled="selected.length > 0 ? false : true" @click="showDialogMulti"
        ><v-icon>mdi-plus</v-icon> Create Report {{ selected.length > 0 ? `(${selected.length})` : null }}</v-btn
      >
    </div>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        fixed-header
        height="640px"
        :headers="headers"
        :items="data"
        :loading="loading"
        :items-per-page="10"
        class="elevation-1"
        loading-text="Loading... Please wait"
        item-key="term"
        sort-by="top_keyword"
        :sort-desc="sort_desc"
        :search="search"
        show-select
        v-model="selected"
        :single-select="singleSelect"
        mobile-breakpoint="0"
        :footer-props="{ 'items-per-page-options': items_per_page }"
      >
        <template v-slot:[`item.term`]="{ item }">
          <span>{{ item.term ? item.term.replace(/[^a-zA-Z0-9\s]/, '') : '' }}</span>
        </template>
        <template v-slot:[`item.position`]="{ item }">
          <span>{{ item.position ? item.position : '' }} </span>
        </template>
        <template v-slot:[`item.full_url`]="{ item }">
          <span>{{ item.full_url ? item.full_url : '' }} </span>
        </template>
        <template v-slot:[`item.exact_global_monthly_search_volume`]="{ item }">
          <span
            >{{
              (item.exact_global_monthly_search_volume ? item.exact_global_monthly_search_volume : 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </span>
        </template>
        <template v-slot:[`item.bid_prank`]="{ item }">
          <span
            >{{
              (item.bid_prank ? item.bid_prank * 100 : 0)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </span>
        </template>
        <template v-slot:[`item.seo_difficulty`]="{ item }">
          <span>{{ item.seo_difficulty ? item.seo_difficulty : '' }} </span>
        </template>
        <template v-slot:[`item.top_keyword`]="{ item }">
          <span>{{ item.top_keyword == 1 ? 'Yes' : 'No' }} </span>
        </template>

        <!--  <template v-slot:[`item.broadClickThroughRate`]="{ item }">
          <span>{{ ((item.broadClickThroughRate ? item.broadClickThroughRate : 0) * 100).toFixed(2) }}% </span>
        </template>
        <template v-slot:[`item.estimated_cost`]="{ item }">
          <span>{{ (item.estimated_cost ? item.estimated_cost : 0).toFixed(2) }} </span>
        </template>
        <template v-slot:[`item.broad_clicks_month`]="{ item }">
          <span>{{ (item.broad_clicks_month ? item.broad_clicks_month : 0).toFixed(0) }} </span>
        </template>
        <template v-slot:[`item.top_keyword`]="{ item }">
          <span>{{ item.top_keyword == 1 ? 'Yes' : 'No' }} </span>
        </template>
        <template v-slot:[`item.second_keyword`]="{ item }">
          <span>{{ item.second_keyword == 1 ? 'Yes' : 'No' }} </span>
        </template>
        <template v-slot:[`item.third_keyword`]="{ item }">
          <span>{{ item.third_keyword == 1 ? 'Yes' : 'No' }} </span>
        </template> -->
        <template v-slot:[`item.report`]="{ item }" v-if="shared != true">
          <v-btn outlined color="primary" rounded @click="sigleItem(item)"> Create Report </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <dialog-url-selected title="Create Report" :item.sync="item" :show.sync="dialog_single.show" :report="report" />

    <dialog-multi-selected
      title="Create Report"
      :items.sync="selected"
      :show.sync="dialog_multi.show"
      :report="report"
    />
  </div>
</template>


<script>
import DialogMultiSelected from './DialogMultiSelected.vue'
import DialogUrlSelected from './DialogUrlSelected.vue'

export default {
  components: { DialogUrlSelected, DialogMultiSelected },
  props: {
    loading: Boolean,
    data: Array,
    report: Object,
    shared: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: 'Keyword',
          value: 'term',
          width: '200px',
        },
        {
          text: 'Client Position',
          value: 'position',
        },
        {
          text: 'Client URL',
          value: 'full_url',
        },
        {
          text: 'Volume',
          value: 'exact_global_monthly_search_volume',
        },
        {
          text: 'BID',
          value: 'bid_prank',
        },
        {
          text: 'Difficulty',
          value: 'seo_difficulty',
        },
        {
          text: 'Top Keywords',
          value: 'top_keyword',
        },
        /* {
          text: 'CPC',
          value: 'broadCostPerClick',
        },
        {
          text: 'CTR',
          value: 'broadClickThroughRate',
        },
        {
          text: 'Estimated Cost',
          value: 'estimated_cost',
        },
        {
          text: 'Broad Clicks / Month',
          value: 'broad_clicks_month',
        },
        {
          text: '1st Opportunity',
          value: 'top_keyword',
        },
        {
          text: '2nd Opportunity',
          value: 'second_keyword',
        },
        {
          text: '3nd Opportunity',
          value: 'third_keyword',
        }, */
        {
          text: 'Report',
          value: 'report',
          sortable: false,
        },
      ],
      sort_desc: true,
      search: '',
      singleSelect: false,
      selected: [],
      item: {},
      dialog_single: {
        show: false,
      },
      dialog_multi: {
        show: false,
      },
      items_per_page: [5, 10, 25, 50, 100],
    }
  },
  created() {
    if (this.shared === true) {
      this.headers = this.headers.filter(item => item.value !== 'report')
    }
  },
  methods: {
    sigleItem(item) {
      this.item = item
      this.dialog_single.show = true
    },
    showDialogMulti() {
      this.dialog_multi.show = true
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
