<template>
  <div>
    <v-alert outlined type="error" prominent border="left">
      <p class="font-weight-semibold mb-1">Messages</p>
      <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
        {{ error }}
      </p>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    errors: Array,
  },
  setup(props) {},
}
</script>
