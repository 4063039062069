<template>
  <div>
    <div class="mt-5">
      <table-filter :headers="headers" :data="data" :updateData="updateData" :setLoading="setLoading" />
    </div>

    <div class="header-top" v-if="shared != true">
      <v-btn color="primary" :disabled="selected.length > 0 ? false : true" @click="showDialogMulti">
        <v-icon>mdi-plus</v-icon> Create Report {{ selected.length > 0 ? `(${selected.length})` : null }}
      </v-btn>
    </div>

    <v-card class="mt-4">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        fixed-header
        height="640px"
        :headers="headers"
        :items="data_filter"
        :loading="loading_table"
        :search="search"
        class="elevation-1"
        loading-text="Loading... Please wait"
        item-key="id"
        sort-by="id"
        :sort-desc="sort_desc"
        v-model="selected"
        :single-select="singleSelect"
        mobile-breakpoint="0"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td colspan="1" class="text-start col-sticky">
              <tr>
                <td class="text-start check-keyword">
                  <v-checkbox v-model="item.selected" @change="setSelected(item)" />
                </td>
                <td class="text-start col-sticky">
                  <span>{{ item.keyword }} </span>
                </td>
              </tr>
            </td>
            <td class="text-start">
              <span>{{ item.rank_absolute ? item.rank_absolute : 'Not Ranking' }}</span>
            </td>
            <td class="text-start">
              <span>{{ item.url ? item.url : 'Not Ranking' }}</span>
            </td>
            <td class="text-start">{{ item.search_volume }}</td>
            <td class="text-start">{{ item.keyword_difficulty }}</td>
            <td class="text-start">{{ item.cpc }}</td>
            <td class="text-start">
              <apex-search-trend :data="item.monthly_searches" />
            </td>
            <td class="text-start">{{ item.presence_score }}</td>
            <td class="text-start">{{ item.position_score }}</td>
            <td class="text-start">{{ item.palomar_score }}</td>
            <td class="text-start">
              <span>{{ item.is_branded === true ? 'Branded' : 'Not Branded' }} </span>
            </td>
            <td class="text-start">{{ item.brand }}</td>

            <td v-for="h in headers_comp" :key="h.value">{{ item[h.value] }}</td>

            <td class="text-start">
              <span>{{ item.keyword_gap ? item.keyword_gap : 'No Gap' }}</span>
            </td>
            <td class="text-start">
              <dialog-url-selected title="Create Report" :item="item" :report="report" :word="item.keyword" />
            </td>
          </tr>
        </template>

        <!-- <template v-slot:[`item.keyword`]="{ item }" class="hello">
          <span>{{ item.keyword }} </span>
        </template>
        <template v-slot:[`item.is_branded`]="{ item }">
          <span>{{ item.is_branded === true ? 'Branded' : 'Not Branded' }} </span>
        </template>
        <template v-slot:[`item.monthly_searches`]="{ item }">
          <apex-search-trend :data="item.monthly_searches" />
        </template>
        <template v-slot:[`item.rank_absolute`]="{ item }">
          <span>{{ item.rank_absolute ? item.rank_absolute : 'Not Ranking' }}</span>
        </template>
        <template v-slot:[`item.url`]="{ item }">
          <span>{{ item.url ? item.url : 'Not Ranking' }}</span>
        </template>
        <template v-slot:[`item.keyword_gap`]="{ item }">
          <span>{{ item.keyword_gap ? item.keyword_gap : 'No Gap' }}</span>
        </template>
        <template v-slot:[`item.report`]="{ item }" v-if="shared != true">
          <dialog-url-selected title="Create Report" :item="item" :report="report" :word="item.keyword" />
        </template> -->
      </v-data-table>
    </v-card>

    <dialog-multi-selected
      title="Create Report"
      :items.sync="selected"
      :show.sync="dialog_multi.show"
      :report="report"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, meh, ref, unref, watch } from '@vue/composition-api'

import DialogMultiSelected from './DialogMultiSelected.vue'
import DialogUrlSelected from './DialogUrlSelected.vue'
import ApexSearchTrend from './ApexSearchTrend.vue'
import TableFilter from '@/components/filters/TableFilter.vue'

export default {
  components: {
    DialogUrlSelected,
    DialogMultiSelected,
    ApexSearchTrend,
    TableFilter,
  },
  props: {
    loading: Boolean,
    data: Array,
    report: Object,
    shared: Boolean,
  },
  setup(props) {
    const items_per_page = ref([5, 10, 25, 50, 100])
    const sort_desc = ref(false)
    const search = ref('')
    const singleSelect = ref(false)
    const selected = ref([])
    const dialog_multi = ref({
      show: false,
    })
    const loading_table = ref(false)

    const data_filter = ref([])

    const headers_comp = computed(() => {
      let headers = []
      for (let i = 1; i <= props.report.competitor_urls.length; i++) {
        headers.push({
          text: `Competitor #${i} Ranking`,
          value: `comp_rank_${i}`,
        })
        headers.push({
          text: `Competitor #${i} Ranking Page`,
          value: `comp_url_${i}`,
          width: 300,
        })
      }
      return headers
    })
    const headers = computed(() => {
      let current_headers = [
        {
          text: 'Keyword',
          value: 'keyword',
          width: '200px',
          class: 'header-sticky',
        },
        {
          text: 'Client Position',
          value: 'rank_absolute',
        },
        {
          text: 'Client URL',
          value: 'url',
        },
        {
          text: 'Search Volume',
          value: 'search_volume',
        },
        {
          text: 'Keyword Difficulty',
          value: 'keyword_difficulty',
        },
        {
          text: 'CPC',
          value: 'cpc',
        },
        {
          text: 'Search Trend',
          value: 'monthly_searches',
          width: 200,
        },
        {
          text: 'Palomar Presence Score',
          value: 'presence_score',
        },
        {
          text: 'Palomar Position Score',
          value: 'position_score',
        },
        {
          text: 'Palomar Score',
          value: 'palomar_score',
        },
        {
          text: 'Is Branded',
          value: 'is_branded',
          width: 120,
        },
        {
          text: 'Brand',
          value: 'brand',
          width: 120,
        },
      ]

      for (let i = 1; i <= props.report.competitor_urls.length; i++) {
        current_headers.push({
          text: `Competitor #${i} Ranking`,
          value: `comp_rank_${i}`,
        })
        current_headers.push({
          text: `Competitor #${i} Ranking Page`,
          value: `comp_url_${i}`,
          width: 300,
        })
      }

      current_headers.push({
        text: 'Keyword Gap',
        value: 'keyword_gap',
      })
      current_headers.push({
        text: 'Report',
        value: 'report',
      })

      return current_headers
    })

    const showDialogMulti = () => {
      dialog_multi.value.show = true
    }
    const updateData = data => {
      data_filter.value = data
    }
    const setLoading = loading => {
      loading_table.value = loading
    }
    const setSelected = item => {
      if (item.selected) {
        let index = selected.value.findIndex(x => x.id === item.id)
        if (index === -1) {
          selected.value.push(item)
        }
      } else {
        let index = selected.value.findIndex(x => x.id === item.id)
        selected.value.splice(index, 1)
      }
    }

    return {
      headers,
      items_per_page,
      sort_desc,
      search,
      singleSelect,
      selected,
      dialog_multi,
      headers_comp,

      loading_table,
      data_filter,

      showDialogMulti,
      updateData,
      setLoading,
      setSelected,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.check {
  margin-top: 0px;
}
.check-keyword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.header-sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}
.col-sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0 !important;
}

.v-application.theme--dark {
  .col-sticky {
    background-color: #312d4b !important;
  }
}
.v-application.theme--light {
  .col-sticky {
    background-color: #fff !important;
  }
}
</style>
