<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="767">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"> Export Data </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Export Data </v-card-title>

        <v-card-text>
          <span>Select a format: </span>
          <v-radio-group v-model="format" row style="display: flex; gap: 40px !important">
            <v-radio label="CSV" value="csv"></v-radio>
            <v-radio label="XLSX" value="xlsx"></v-radio>
            <v-radio label="XLS" value="xls"></v-radio>
            <v-radio label="ODS" value="ods"></v-radio>
          </v-radio-group>

          <div style="margin: 0px 0px 0px 0px">
            <div class="format-row">
              <v-btn
                v-if="type === 'organic'"
                class="btn"
                color="primary"
                @click="downloadReport('organic')"
                :disabled="loading.organic"
              >
                <template v-if="!loading.organic"
                  >Download Data <v-icon dark right> mdi-export-variant</v-icon></template
                >
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                v-else-if="type === 'paid'"
                class="btn"
                color="primary"
                @click="downloadReport('paid')"
                :disabled="loading.paid"
              >
                <template v-if="!loading.paid">Download Data <v-icon dark right> mdi-export-variant</v-icon></template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                v-else-if="type === 'ranked_keywords'"
                class="btn"
                color="primary"
                @click="downloadReport('ranked_keywords')"
                :disabled="loading.ranked_keywords"
              >
                <template v-if="!loading.ranked_keywords"
                  >Download Data <v-icon dark right> mdi-export-variant</v-icon></template
                >
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    report: Object,
    type: String,
  },
  data() {
    return {
      dialog: false,
      format: 'csv',
      loading: {
        organic: false,
        paid: false,
        ranked_keywords: false,
      },
    }
  },
  methods: {
    ...mapActions({
      exportReport: 'keyword_research/export',
    }),
    async downloadReport(view) {
      const data = {
        format: this.format,
        type: this.type,
        report_id: this.report.id,
      }

      this.loading[view] = true

      const resp = await this.exportReport(data)
        .catch(error => [console.log(error)])
        .finally(() => {
          this.loading[view] = false
        })

      if (resp.status === 200 && resp.data.status == 'ok') {
        let link = document.createElement('a')
        link.href = resp.data.url
        link.target = '_blank'
        link.click()
        link.remove()
      } else {
        console.log(resp)
      }
    },
  },
}
</script>

<style scoped>
.btn {
  display: block !important;
  flex: none !important;
  white-space: initial !important;
}
.format-row {
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

@media (max-width: 992px) {
  .format-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 580px) {
  .format-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
