<template>
  <vue-apex-charts height="50" :options="chartOptions" :series="series" />
</template>
<script>
import store from '@/store'
import { onBeforeMount, ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import VueApexCharts from 'vue-apexcharts'
import themeConfig from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Array,
  },
  setup(props) {
    const $themeColors = themeConfig.themes.light

    const series = computed(() => {
      return [
        {
          name: '',
          data: props.data,
        },
      ]
    })

    const chartOptions = {
      chart: {
        type: 'line',
        height: 50,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return seriesName
            },
          },
        },
        marker: {
          show: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 3,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
      },
      xaxis: {
        type: 'number',
      },
      yaxis: {
        opposite: true,
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    }

    return { series, chartOptions }
  },
}
</script>
