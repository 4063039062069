<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else>
    <no-found-report v-if="!!errors" :errors="errors" />

    <template v-else>
      <h2 class="view-title">Keyword Report</h2>

      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span>Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span v-if="report.type === 'paid'">Report Type: {{ 'Paid' }} </span>
            <span v-if="report.type === 'organic'">Report Type: {{ 'Organic' }} </span>
            <span v-if="report.type === 'ranked_keywords'">Report Type: {{ 'Ranked Keywords' }} </span>
            <span v-if="report.type === 'cluster_keywords'">{{ 'Cluster Keywords' }} </span>
          </div>
          <div class="row-text" v-if="report.client_url">
            <span>
              <span>Client URL:</span>
              <a :href="externalLink(report.client_url)">{{ report.client_url }}</a>
            </span>
            <span v-if="report.type === 'cluster_keywords' || report.type === 'cluster_keywords'">
              Brand: {{ report.client_brand }}
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison" v-if="report.type === 'paid'">
              <div class="url-brand" v-for="(url, i) in report.competitor_urls" :key="i">
                <span>
                  URL: {{ i + 1 }}: <a :href="externalLink(url)">{{ url }}</a>
                </span>
              </div>
            </div>
            <div class="col-comparison" v-if="report.type === 'ranked_keywords' || report.type === 'cluster_keywords'">
              <div class="url-brand" v-for="(competitor, i) in report.competitor_urls" :key="i">
                <span>
                  URL: {{ i + 1 }}: <a :href="externalLink(competitor.url)">{{ competitor.url }}</a>
                </span>
                <span> Brand {{ i + 1 }}: {{ competitor.brand }} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-actions text-center">
          <dialog-export :report="report" :type="report.type" />
          <dialog-share-report v-if="report && report.id" :report="report" report_type="keyword_research" />
        </div>
      </div>
    </template>

    <template v-if="!loading">
      <div>
        <div class="cont-table" v-if="report.type == 'paid' || report.type == 'paid_keywords'">
          <table-report-paid :loading="loading" :data="keywords" :report="report" />
        </div>
        <div class="cont-table" v-if="report.type == 'ranked_keywords' || report.type == 'cluster_keywords'">
          <table-ranked-keyword :loading="loading" :data="ranked_keywords" :report="report" />
        </div>
      </div>
    </template>
  </v-container>
</template>

  <script>
import store from '@/store'
import { onBeforeMount, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'

import ViewLoader from '@/components/spinner/ViewLoader.vue'
import TableReportPaid from './components/TableReportPaid.vue'
import TableReportOrganic from './components/TableReportOrganic.vue'
import TableRankedKeyword from './components/TableRankedKeyword.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'
import DialogExport from './components/DialogExport.vue'
import TableFilter from '@/components/filters/TableFilter.vue'
//components
import NoFoundReport from '@/components/report/NoFoundReport.vue'

export default {
  components: {
    ViewLoader,
    TableReportPaid,
    TableReportOrganic,
    TableRankedKeyword,
    DialogShareReport,
    DialogExport,
    NoFoundReport,
    TableFilter,
  },
  setup(props) {
    const { route, router } = useRouter()

    const loading = ref(false)
    const errors = ref(null)

    const report = ref({})
    const keywords = ref(null)
    const ranked_keywords = ref(null)
    const cluster_keywords = ref(null)

    const search = ref('')
    const tab = ref(null)
    const all = ref([])
    const starred = ref([])

    onBeforeMount(() => {
      getReportData()
    })

    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }
    const getReportData = async () => {
      const data = new URLSearchParams({ ...route.value.params }).toString()

      loading.value = true
      await store
        .dispatch('keyword_research/report', data)
        .then(resp => {
          if (resp && resp.data) {
            report.value = resp.data.keyword_research

            if (report.value.type === 'ranked_keywords') {
              ranked_keywords.value = resp.data.ranked_keywords.map((item, index) => {
                return {
                  id: index + 1,
                  ...item,
                  selected: false,
                }
              })
            } else if (report.value.type === 'cluster_keywords') {
              ranked_keywords.value = resp.data.ranked_keywords.map((item, index) => {
                return {
                  id: index + 1,
                  ...item,
                  selected: false,
                }
              })
              cluster_keywords.value = resp.data.cluster_keywords
            } else {
              keywords.value = resp.data.keywords
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            errors.value = error.response.data.errors
          } else {
            errors.value = null
          }
          setSnackbar({
            status: true,
            text: 'No report found.',
            color: 'error',
          })
        })
        .finally(() => {
          loading.value = false
        })
    }
    const newReport = () => {
      const path = '/kw-research/new'
      router.push({ path })
    }
    const externalLink = url => {
      if (url.includes('https://') || url.includes('http://')) {
        return url
      } else {
        return 'https://' + url
      }
    }

    return {
      loading,
      errors,

      report,
      keywords,
      ranked_keywords,
      cluster_keywords,

      search,
      tab,
      all,
      starred,

      newReport,
      externalLink,
    }
  },
}
</script>

  <style scoped>
.header-report {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.url-brand {
  display: flex;
  gap: 20px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cont-tables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.cont-table:nth-child(n + 2) {
  margin-top: 70px;
}
</style>

