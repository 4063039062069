<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="750" class="p-4">
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>
          <v-form ref="form" class="form-report">
            <v-select
              class="pt-3"
              v-model="report_type"
              :items="report_types"
              label="Select Report"
              item-text="name"
              item-value="name"
              outlined
              :rules="[validators.required]"
            />
            <v-text-field
              name="report_name"
              v-model="report_name"
              label="Report Name"
              outlined
              placeholder="Report Name"
              hint="Report Name"
              persistent-hint
              hide-details="auto"
              :rules="[validators.required]"
            />

            <v-expansion-panels v-model="panel" :disabled="disabled" multiple class="pb-4">
              <v-expansion-panel v-for="(item, i) in keyword_data" :key="i">
                <v-expansion-panel-header>
                  <div class="keyword-row">
                    <v-btn icon @click="removeKeyword(i)"> <v-icon color="red"> mdi-close-circle </v-icon> </v-btn>
                    <span>Keyword:</span> <span class="keyword">{{ item.keyword }}</span>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-text-field
                    v-model="item.keyword"
                    label="Keyword"
                    hint="Enter the keyword"
                    persistent-hint
                    outlined
                    placeholder="Keyword"
                    hide-details="auto"
                    :rules="[validators.required]"
                  />
                  <v-text-field
                    v-for="(url, i) in item.comp_urls"
                    :key="i"
                    v-model="url.value"
                    label="Comparsion URL"
                    hint="Enter the URL of the competitor"
                    persistent-hint
                    outlined
                    placeholder="Comparsion URL"
                    :append-icon="url.remove ? 'mdi-close' : ''"
                    @click:append="removeUrl(item.comp_urls, i)"
                    hide-details="auto"
                    :rules="[validators.required, validators.urlValidator]"
                  />
                  <div v-if="item.comp_urls.length < 5">
                    <v-btn text @click="addUrl(item.comp_urls)">
                      <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div>
              <v-btn text @click="addNewKeyword()" class="btn-more">
                <v-icon dark left> mdi-plus </v-icon> add more
              </v-btn>
            </div>
          </v-form>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="cont-actions">
          <div>
            <span>Total: {{ `${items.length} ${items.length > 1 ? 'Reports' : 'Report'} ` }}</span>
          </div>
          <div>
            <v-btn color="green darken-1" text @click="closeDialog"> Close </v-btn>
            <v-btn color="primary" outlined :disabled="loading" @click="submit">
              <span v-if="loading === false">Continue</span>
              <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapActions } from 'vuex'
import { required, urlValidator } from '@core/utils/validation'
import FormDialog from './FormDialog.vue'

export default {
  components: { FormDialog },
  props: {
    show: Boolean,
    title: String,
    items: Array,
    report: Object,
  },
  data() {
    return {
      loading: false,
      loadingLimit: true,

      errors: null,

      panel: [0],
      disabled: false,
      report_name: '',
      data: [],
      keyword_data: [],
      report_type: '',
      report_types: [
        { id: 1, name: 'Semantic Analysis' },
        { id: 2, name: 'SERP Analysis' },
        { id: 3, name: 'Full Report' },
      ],

      validators: {
        required,
        urlValidator,
      },
    }
  },
  watch: {
    items(values) {
      this.data = values
      this.showData()
    },
  },
  mounted() {
    this.showData()
  },
  methods: {
    ...mapActions({
      createMultiSerp: 'serp_analysis/createMulti',
      createMultiSemantic: 'semantic_analysis/createMulti',
      createMultiFull: 'full_reports/createMulti',
      setSnackbar: 'snackbar/set',
    }),

    showData() {
      this.keyword_data = this.items.map(item => {
        if (this.report.type === 'paid') {
          return {
            keyword: item.term,
            comp_urls: [],
          }
        } else if (this.report.type === 'ranked_keywords' || this.report.type === 'organic') {
          return {
            keyword: item.keyword,
            comp_urls: item.url ? [{ value: item.url, remove: true }] : [],
          }
        }
      })
    },
    closeDialog() {
      this.cleanFields()
      this.$emit('update:show', false)
    },
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl(competitor_urls) {
      let index = competitor_urls.length
      this.$set(competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(competitor_urls, index) {
      competitor_urls.splice(index, 1)
    },
    cleanFields() {
      this.showData()
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let data = this.keyword_data.map(item => {
          return {
            keyword: item.keyword,
            comp_urls: item.comp_urls.map(comp => comp.value),
          }
        })

        let form_data = {
          report_name: this.report_name,
          data: data,
        }

        if (this.report_type === 'SERP Analysis') {
          this.loading = true
          await this.createMultiSerp(form_data)
            .then(resp => {
              if (resp && resp.data) {
                // let path = '/serp-analysis/list'
                // this.goToPath(path)

                this.closeDialog()
                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }

        if (this.report_type === 'Semantic Analysis') {
          this.loading = true
          await this.createMultiSemantic(form_data)
            .then(resp => {
              if (resp && resp.data) {
                // let path = '/semantic-analysis/history/'
                // this.goToPath(path)
                this.closeDialog()

                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }

        if (this.report_type === 'Full Report') {
          this.loading = true
          await this.createMultiFull(form_data)
            .then(resp => {
              if (resp && resp.data) {
                // let path = 'full-report/list'
                // this.goToPath(path)

                this.closeDialog()
                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },

    addNewKeyword() {
      const keyword = {
        keyword: '',
        comp_urls: [],
      }

      this.items.push(keyword)
    },
    removeKeyword(index) {
      this.items.splice(index, true)
    },
  },
}
</script>

<style scope>
.keyword-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}
.keyword {
  color: #2ecc71;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.btn-more {
  padding: 10px !important;
}
</style>
