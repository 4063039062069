<template>
  <v-dialog v-model="dialog" persistent max-width="750" class="p-4">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined color="primary" rounded v-bind="attrs" v-on="on"> Create Report </v-btn>
    </template>

    <v-card class="p-3">
      <v-card-title class="text-h5">{{ title }}</v-card-title>

      <v-card-text class="mt-4">
        <v-form id="form_dialog_create" ref="form" class="form-report">
          <v-select
            v-model="report_type"
            :items="report_types"
            label="Select Report"
            item-text="name"
            item-value="name"
            outlined
            :rules="[validators.required]"
          />
          <v-text-field
            v-model="report_name"
            label="Report Name"
            outlined
            placeholder="Report Name"
            hint="Report Name"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-text-field
            v-model="keyword"
            label="Keyword"
            hint="Enter the keyword"
            persistent-hint
            outlined
            placeholder="Keyword"
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-text-field
            v-for="(url, i) in competitor_urls"
            :key="'dialog' + i"
            v-model="url.value"
            label="Comparsion URL"
            hint="Enter the URL of the competitor"
            persistent-hint
            outlined
            placeholder="Comparsion URL"
            :append-icon="url.remove ? 'mdi-close' : ''"
            @click:append="removeUrl(i)"
            hide-details="auto"
            :rules="[validators.required, validators.urlValidator]"
          />
          <div v-if="competitor_urls.length < 5">
            <v-btn text @click="addUrl" class="btn-more">
              <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL
            </v-btn>
          </div>
        </v-form>

        <v-alert v-if="errors" class="mt-4" color="error" text>
          <p class="font-weight-semibold mb-1">Messages</p>
          <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
            {{ error }}
          </p>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false"> Close </v-btn>
        <v-btn color="primary" outlined :disabled="loading" form="form_dialog_create" @click="submit">
          <span v-if="loading === false">Continue</span>
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" top color="primary">
      Request submitted successfully.

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="closeDialog"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>


<script>
import { mapActions } from 'vuex'
import { required, urlValidator } from '@core/utils/validation'

export default {
  props: {
    title: String,
    description: String,
    item: Object,
    report: Object,
    word: String,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingLimit: true,

      errors: null,

      keyword: '',

      snackbar: false,
      timeout: 4000,
      report_name: '',
      competitor_urls: [],
      match_type: { label: 'Phrase', value: 'phrase' },
      report_type: '',
      report_types: [
        { id: 1, name: 'Semantic Analysis' },
        { id: 2, name: 'SERP Analysis' },
        { id: 3, name: 'Full Report' },
      ],

      validators: {
        required,
        urlValidator,
      },
    }
  },
  watch: {
    dialog: {
      handler(value, oldValue) {
        this.keyword = this.word
        let urls = []
        if (this.report.type === 'paid') {
          if (this.item.full_url) {
            urls.push({ value: this.item.full_url, remove: true })
          }
        } else if (this.report.type === 'ranked_keywords' || this.report.type === 'organic') {
          if (this.item.url) {
            urls.push({ value: this.item.url, remove: true })
          }
        }
        this.competitor_urls = urls
      },
      inmediate: true,
    },
  },
  methods: {
    ...mapActions({
      createSerpAnalysis: 'serp_analysis/createReport',
      createSemantincAnalysis: 'semantic_analysis/createReport',
      createFullReport: 'full_reports/createReport',
      setSnackbar: 'snackbar/set',
    }),

    closeDialog() {
      this.cleanFields()
    },
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl() {
      let index = this.competitor_urls.length
      this.$set(this.competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(index) {
      this.competitor_urls.splice(index, 1)
    },
    cleanFields() {
      this.competitor_urls = []
      this.$refs.form.reset()
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const comp_urls = await this.competitor_urls.map(url => url.value)

        const form_data = {
          report_name: this.report_name,
          keyword: this.keyword,
          comp_urls,
        }

        if (this.report_type === 'SERP Analysis') {
          this.loading = true
          await this.createSerpAnalysis(form_data)
            .then(resp => {
              if (resp && resp.data) {
                /* let path = '/serp-analysis/await/' + resp.data.id
                this.goToPath(path) */

                this.closeDialog()

                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }

        if (this.report_type === 'Semantic Analysis') {
          this.loading = true
          await this.createSemantincAnalysis(form_data)
            .then(resp => {
              if (resp && resp.data) {
                const { report_id, serp_data_id, success } = resp.data
                /* let path = '/semantic-analysis/await/' + report_id
                  this.goToPath(path) */
                this.closeDialog()

                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }

        if (this.report_type === 'Full Report') {
          this.loading = true
          await this.createFullReport(form_data)
            .then(resp => {
              console.log(resp)
              if (resp && resp.data) {
                const { report_id, serp_data_id, success } = resp.data
                /*  let path = '/full-report/await/' + report_id
                this.goToPath(path) */

                this.closeDialog()

                this.setSnackbar({
                  status: true,
                  text: 'The report has been added to the queue.',
                  color: 'primary',
                })
              }
            })
            .catch(error => {
              console.log(error)
              if (error.response.data && error.response.data.status === 'error') {
                this.errors = error.response.data.errors
              } else {
                this.errors = null
              }

              this.setSnackbar({
                status: true,
                text: 'Failed to create report.',
                color: 'error',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>

<style scope>
.btn-more {
  padding: 10px !important;
}
</style>
