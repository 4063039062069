var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.shared != true)?_c('div',{staticClass:"header-top"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length > 0 ? false : true},on:{"click":_vm.showDialogMulti}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Report "+_vm._s(_vm.selected.length > 0 ? ("(" + (_vm.selected.length) + ")") : null))],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"640px","headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"items-per-page":10,"loading-text":"Loading... Please wait","item-key":"term","sort-by":"top_keyword","sort-desc":_vm.sort_desc,"search":_vm.search,"show-select":"","single-select":_vm.singleSelect,"mobile-breakpoint":"0","footer-props":{ 'items-per-page-options': _vm.items_per_page }},scopedSlots:_vm._u([{key:"item.term",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.term ? item.term.replace(/[^a-zA-Z0-9\s]/, '') : ''))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.position ? item.position : '')+" ")])]}},{key:"item.full_url",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.full_url ? item.full_url : '')+" ")])]}},{key:"item.exact_global_monthly_search_volume",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.exact_global_monthly_search_volume ? item.exact_global_monthly_search_volume : 0) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ','))+" ")])]}},{key:"item.bid_prank",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.bid_prank ? item.bid_prank * 100 : 0) .toFixed(2) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ','))+" ")])]}},{key:"item.seo_difficulty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.seo_difficulty ? item.seo_difficulty : '')+" ")])]}},{key:"item.top_keyword",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.top_keyword == 1 ? 'Yes' : 'No')+" ")])]}},(_vm.shared != true)?{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.sigleItem(item)}}},[_vm._v(" Create Report ")])]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('dialog-url-selected',{attrs:{"title":"Create Report","item":_vm.item,"show":_vm.dialog_single.show,"report":_vm.report},on:{"update:item":function($event){_vm.item=$event},"update:show":function($event){return _vm.$set(_vm.dialog_single, "show", $event)}}}),_c('dialog-multi-selected',{attrs:{"title":"Create Report","items":_vm.selected,"show":_vm.dialog_multi.show,"report":_vm.report},on:{"update:items":function($event){_vm.selected=$event},"update:show":function($event){return _vm.$set(_vm.dialog_multi, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }