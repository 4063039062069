<template>
  <v-form ref="form" class="form-report">
    <v-text-field
      v-model="keyword"
      label="Keyword"
      hint="Enter the keyword"
      persistent-hint
      outlined
      placeholder="Keyword"
      hide-details="auto"
      :rules="[required]"
    />
    <v-text-field
      v-for="(url, i) in competitor_urls"
      :key="i"
      v-model="url.value"
      label="Comparsion URL"
      hint="Enter the URL of the competitor"
      persistent-hint
      outlined
      placeholder="Comparsion URL"
      :append-icon="url.remove ? 'mdi-close' : ''"
      @click:append="removeUrl(i)"
      hide-details="auto"
      :rules="[required, urlValidator]"
    />
    <div>
      <v-btn text @click="addUrl"> <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL </v-btn>
    </div>
  </v-form>
</template>

<script>
import { required, urlValidator } from '@core/utils/validation'

export default {
  props: {
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      report_name: '',
      keyword: this.item ? this.item.term : '',
      competitor_urls: [],
      match_type: { label: 'Phrase', value: 'phrase' },
    }
  },
  watch: {
    item(value) {
      //keyword
      this.keyword = value.term

      // comparison URL
      // let urls = [];
      // if(this.item.client_url){
      //   urls.push({ value: this.item.client_url, remove: true })
      // }
      // this.competitor_urls = urls;
    },
  },
  methods: {
    required: required,
    urlValidator: urlValidator,
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl() {
      let index = this.competitor_urls.length
      this.$set(this.competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(index) {
      this.competitor_urls.splice(index, 1)
    },
    cleanFields() {
      this.competitor_urls = []
      this.$refs.form.reset()
    },
    closeDialog() {
      this.cleanFields()
    },
    submit() {
      // if (this.$refs.form.validate()) {
      //   console.log('new report')
      // }
    },
  },
}
</script>

<style scope>
</style>
