<template>
  <div>
    <div class="header-top" v-if="shared != true">
      <v-btn color="primary" :disabled="selected.length > 0 ? false : true" @click="showDialogMulti"
        ><v-icon>mdi-plus</v-icon> Create Report {{ selected.length > 0 ? `(${selected.length})` : null }}</v-btn
      >
    </div>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        ref="table"
        fixed-header
        height="640px"
        v-columns-resizable
        :headers="headers"
        :items="keywords"
        :loading="loading"
        :items-per-page="10"
        class="elevation-1"
        loading-text="Loading... Please wait"
        item-key="term"
        sort-by="top_keyword"
        :sort-desc="sort_desc"
        :search="search"
        show-select
        v-model="selected"
        :single-select="singleSelect"
        mobile-breakpoint="0"
        :footer-props="{ 'items-per-page-options': items_per_page }"
      >
        <template v-slot:[`item.star`]="{ item }">
          <v-icon>mdi-star</v-icon>
        </template>
        <template v-slot:[`item.report`]="{ item }" v-if="shared != true">
          <dialog-url-selected title="Create Report" :item="item" :report="report" :word="item.term" />
        </template>
      </v-data-table>
    </v-card>

    <dialog-multi-selected
      title="Create Report"
      :items.sync="selected"
      :show.sync="dialog_multi.show"
      :report="report"
    />
  </div>
</template>


<script>
import DialogMultiSelected from './DialogMultiSelected.vue'
import DialogUrlSelected from './DialogUrlSelected.vue'

export default {
  components: { DialogUrlSelected, DialogMultiSelected },
  props: {
    loading: Boolean,
    data: Array,
    report: Object,
    shared: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: 'Keyword',
          value: 'term',
          width: '200px',
        },
        {
          text: 'Volume',
          value: 'search_volume',
        },
        {
          text: 'CPC',
          value: 'average_cpc',
        },
        {
          text: 'CTR',
          value: 'ctr',
        },
        {
          text: 'Estimated Cost',
          value: 'estimated_cost',
        },
        {
          text: 'Broad Clicks / Month',
          value: 'broad_clicks_month',
        },
        {
          text: '1st Opportunity',
          value: 'top_keyword',
        },
        {
          text: '2nd Opportunity',
          value: 'second_keyword',
        },
        {
          text: '3nd Opportunity',
          value: 'third_keyword',
        },
        {
          text: 'Report',
          value: 'report',
          sortable: false,
        },
      ],
      sort_desc: true,
      search: '',
      singleSelect: false,
      selected: [],
      item: {},
      dialog_single: {
        show: false,
      },
      dialog_multi: {
        show: false,
      },
      items_per_page: [5, 10, 25, 50, 100],
    }
  },
  created() {
    if (this.shared === true) {
      this.headers = this.headers.filter(item => item.value !== 'report')
    }
  },
  computed: {
    keywords() {
      return this.formatData(this.data)
    },
  },
  methods: {
    sigleItem(item) {
      this.item = item
      this.dialog_single.show = true
    },
    showDialogMulti() {
      this.dialog_multi.show = true
    },
    formatData(values) {
      return values.map(item => {
        item.search_volume = item.search_volume
        item.term = item.term.replace(/[^a-zA-Z0-9\s]/, '')
        item.average_cpc = (item.average_cpc ? item.average_cpc : 0).toFixed(2)
        item.ctr = ((item.ctr ? item.ctr : 0) * 100).toFixed(2) + '%'
        item.estimated_cost = (item.estimated_cost ? item.estimated_cost : 0).toFixed(2)
        item.broad_clicks_month = (item.broad_clicks_month ? item.broad_clicks_month : 0).toFixed(0)
        item.top_keyword = item.top_keyword == 1 ? 'Yes' : 'No'
        item.second_keyword = item.second_keyword == 1 ? 'Yes' : 'No'
        item.third_keyword = item.third_keyword == 1 ? 'Yes' : 'No'
        return item
      })
    },
    formatDataOld(values) {
      return values.map(item => {
        item.monthlyGlobalExactSearchVolume = item.monthlyGlobalExactSearchVolume
        item.term = item.term.replace(/[^a-zA-Z0-9\s]/, '')
        item.broadCostPerClick = (item.broadCostPerClick ? item.broadCostPerClick : 0).toFixed(2)
        item.broadClickThroughRate =
          ((item.broadClickThroughRate ? item.broadClickThroughRate : 0) * 100).toFixed(2) + '%'
        item.estimated_cost = (item.estimated_cost ? item.estimated_cost : 0).toFixed(2)
        item.broad_clicks_month = (item.broad_clicks_month ? item.broad_clicks_month : 0).toFixed(0)
        item.top_keyword = item.top_keyword == 1 ? 'Yes' : 'No'
        item.second_keyword = item.second_keyword == 1 ? 'Yes' : 'No'
        item.third_keyword = item.third_keyword == 1 ? 'Yes' : 'No'
        return item
      })
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
